import pic1 from './pictures/1.jpg';
import pic2 from './pictures/2.jpg';
import pic3 from './pictures/3.jpg';
import pic4 from './pictures/4.jpg';
import pic6 from './pictures/6.jpg';
import pic7 from './pictures/7.jpg';
import pic8 from './pictures/8.jpg';
import CookieConsent from "react-cookie-consent";
import './App.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Christmas In Ansley';
<meta http-equiv="Content-Security-Policy" content="default-src 'self' https://www.google.com; script-src 'self'; style-src 'self';"></meta>

function App() {
    const [daysRemaining, setDaysRemaining] = useState(0);

    const calculateDaysUntilChristmas = () => {
      const today = new Date();
      const christmasDate = new Date(today.getFullYear(), 11, 25); // Christmas is on December 25th
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round((christmasDate - today) / millisecondsInADay);
      setDaysRemaining(diffDays);
    };
  
    useEffect(() => {
      calculateDaysUntilChristmas();
    }, []);
    function Accordion({ items }) {
        const [expanded, setExpanded] = useState(false);
      
        return (
          <div className="flex flex-col items-center justify-center ">
            <button
              className={`text-white bg-green-400 px-4 py-2 rounded-md focus:outline-none mt-8 ${expanded ? 'expanded' : ''}`}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Hide Rules' : 'Please Follow These Rules on Christmas In Ansley Property'}
            </button>
            {expanded && (
              <ul className="text-white mt-4">
                {items.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            )}
          </div>
        );
      }
    const rules = [
        'No alcohol or tabacco use.',
        'Do not allow children to run or cross into the display areas in the walk through.',
        'Speed Limit throughout the entire exhibit is 3 MPH.',
        'Do not exit your vehicle in the drive through area. The first 8 acres are to be seen FROM your vehicle only.',
        'Please do not come dressed as Santa or the Grinch as we reserve those characters for special nights.',
        'All clothing must be in good taste, no vulgarity etc.',
        'Many of our photo props are available to take pictures on without a cost.',
        '3 of our photo props require a ticket to be purchased to use. This is a small fee but it does help us to recoup some of the costs for purchasing these items.',
        'No photo shoots or commercial video without permission.',
        // Add more rules as needed
      ];
  return (
<body class="bg-gradient-to-b from-rose-800 to-rose-500">
    
  <title>Christmas In Ansley</title>
  <link rel="preconnect" href="https://fonts.googleapis.com"></link>
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
  <link href="https://fonts.googleapis.com/css2?family=Mountains+of+Christmas&display=swap" rel="stylesheet"></link>
  <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
  <CookieConsent>This website uses cookies to enhance the user experience. By clicking "I understand" our using our site you consent to the use of cookies as listed in our <a class="text-red-400" href="https://samuelbozeman.github.io/christmasinansleyprivacypolicy/privacypolicy.html">Privacy Policy</a></CookieConsent>
  
  <header>
  <div class="grid items-center justify-center text-5xl font-mountains-of-christmas pt-5 pb-5">

 </div>

 <body>
      

      <header>
        <div className="grid items-center justify-center text-5xl font-mountains-of-christmas pt-5 pb-5">
        <span class="text-white text-6xl transition-all duration-300 transform hover:scale-110">Christmas In Ansley</span>
        </div>

        {/* Christmas countdown */}
        <div className="text-white text-center mb-8">
          <p className="text-4xl font-bold">Countdown to Christmas</p>
          <p className="text-6xl font-extrabold transform hover:scale-150 duration-300 text-green-400">{daysRemaining}</p>
          <p className="text-2xl font-extrabold transform hover:scale-150 duration-300 text-green-400">Days</p>
        </div>
      </header>

     
    
    </body>
    
    <div className="flex items-center text-center text-2xl justify-center">
      <Accordion className="text-1xl" items={rules} />
    </div>
    
   </header>  
   
   <button id="theme-toggle" type="button" class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
    <svg id="theme-toggle-dark-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>
    <svg id="theme-toggle-light-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
</button>
    <section>
        
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <div class="font-light text-white dark:text-white sm:text-lg">
                <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-white dark:text-white font-mountains-of-christmas text-center ">A Taste of Christmas in The South</h2>
                <p class="mb-4 indent-6">  Going three years strong we've been committed to doing this attraction every year. Help from our family, friends, and sponsors have ensured that we can continue to grow and continue celebrating the Christmas season with everyone around.</p>
                <p>Open starting Nov. 23 (5:30 PM - 9PM). Closed on Mondays. Refer to Facebook for scheduling changes.</p>
                <p>5735 County Road 1135, Troy, AL, United States, Alabama</p>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-8">
                <img class="w-full rounded-lg transition-all duration-300 transform hover:scale-150" src={pic1} alt="Shanna and Aaron Taylor"></img>
                <img class="w-full rounded-lg transition-all duration-300 transform hover:scale-150" src={pic7} alt="Christmas in Ansley Military and Veteran Appreciation"></img>

            </div>
        </div>
    </section>

{/* Testimonials */}

<section>
  <div class="py-8 px-4 mx-auto max-w-screen-xl dark text-center lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white font-mountains-of-christmas">Testimonials</h2>
          <p class="mb-8 font-light text-white lg:mb-16 sm:text-xl font-farro">Thank you to everybody who takes their time to review and write about Christmas in Ansley</p>
      </div> 
      
      <div class="grid mb-8 lg:mb-12 lg:grid-cols-2">
          <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
              <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                  <a href="https://www.wsfa.com/2021/12/17/county-road-12-christmas-ansley-is-booming/" class="text-lg font-semibold text-gray-900 dark:text-white italic">"Griswold Proportions"</a>
                  <p class="my-4">"It’s called Christmas in Ansley, and he took this thing to Griswold proportions. Just outside of Troy in Pike County, you can drive through seven acres of lights and holiday displays. If you want to walk, there are treats, a little shopping, and a whole lot more to see."</p>
                  <p class="my-4">"You’ll see holiday favorites, a little sports themes mixed in, maybe even bump into Buddy the Elf."</p>
                  
              </blockquote>
              <figcaption class="flex justify-center items-center space-x-3">
                  <div class="space-y-0.5 font-medium text-red-400 text-left">
                      <div>WSFA 12 News</div>
                      <div class="text-sm font-light text-green-400">Local Alabama Newstation</div>
                  </div>
              </figcaption>    
          </figure>
          <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
              <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                  <a href="https://www.troymessenger.com/2019/12/27/dazzling-lights-christmas-in-ansley-brings-joy-to-the-season/" class="text-lg font-semibold text-gray-900 dark:text-white italic">"Dazzling Lights"</a>
                  <p class="my-4">"The Taylors have transformed more than an acre around their home into a wonderland that is all-aglow with the characters of Christmas and spotlights the real reason for the season, the birth of the Christ Child. The Taylors have been openly and outwardly celebrating the holiday season with “Christmas in Ansley” for about four years."</p>
                  <p class="my-4">"The Taylors’ hope is that “Christmas in Ansley” brings the joy of Christmas to others and that it also brings added awarenss that the Christ Child is the real reason for the season."</p>
                  
              </blockquote>
              <figcaption class="flex justify-center items-center space-x-3">
                  <div class="space-y-0.5 font-medium text-red-400 text-left">
                      <div>Troy Messenger</div>
                      <div class="text-sm font-light text-green-400">Pike County Based Newspaper</div>
                  </div>
              </figcaption>  
          </figure>
          <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 lg:border-b-0 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
              <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                  <a href="https://www.southaec.com/news/2022/christmas-in-ansley" class="text-lg font-semibold text-gray-900 dark:text-white italic">"Lights All Aglow"</a>
                  <p class="my-4">"The impressive light display grows each year, but the displays are truly a year-round labor of love because the family constantly works on the land, widening lanes, grinding stumps, taking care of undergrowth and performing other maintenance to make it possible to handle the crowds. Both Aaron and Shanna work full-time jobs — Shanna is a cosmetologist and Aaron is an engineering assistant for Alabama Department of Transportation."</p>
                  <p class="my-4">"The driving force that keeps the Taylors going year after year is seeing the joy on people’s faces."</p>
                  
              </blockquote>
              <figcaption class="flex justify-center items-center space-x-3">
                  <div class="space-y-0.5 font-medium text-red-400 text-left">
                      <div>South Alabama Electric Cooperative</div>
                      <div class="text-sm font-light text-green-400">A Touchstone Energy Cooperative</div>

                  </div>
              </figcaption>    
          </figure>
          <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
          <img class="w-full rounded-lg sm:rounded-none sm:rounded-l-lg transition-all duration-300 transform hover:scale-110" src={pic8}></img>
          </figure>
      </div>

      </div>
</section>

{/* Donations */}

    {/* Sponsors */}
    <section>
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 dark">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-red-500 dark:text-white font-mountains-of-christmas">Our Sponsors</h2>
          <p class="font-light text-red-500 dark:text-white lg:mb-16 sm:text-xl ">Without our sponsors this wouldn't be possible. Thank you!</p>
      </div> 
      <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
    
      <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Bry Logging</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Eclips Hat Bar</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Eclips Hair Studio</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Justalil Rodeo Company</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Flowers and White Engineering, LLC</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Patriot Excavation and Land Management</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Hamm's HVAC Services LLC</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Tim Sims</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Adams Transport</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">S&K Lawn and Equipment</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Troy Paint and Body</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Southern Animal Hospital</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Earl Smith Homebuilders</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Ansley Baptist Church</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">God's Way Baptist Church</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Troy Animal Clinic</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Half Shell</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Eclips Hair Studio</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Britney Linzey, Real Estate Agent</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Complete AgriServices</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Jones Medical Supply</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Johnson Labs/Karla Johnson</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Pondstocker 2</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Eclips Trunk</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Buttered Home</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Dean's House Cleaning</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Ward's Powersports</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Bryant Smith State Farm</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">All Access Overhead Door</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Hook's BBQ</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Renfroe Contracting</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Max Hughes Trucking</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">May's Distribution</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Bows and Bubbles</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Parker Spray Foam</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Preston's</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Jojo Meredith</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Resolute Accounting Services</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Charles Turnipseed, Attorney</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Troy Piggly Wiggly</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">South Alabama Electric</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Meeksville VFD</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Luverne Truck Wash</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Sellers Realty</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Troy Feed & Seed</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Grady Air</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Thompson Gas</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Troy Feed and Seed</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Corner</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Pike County Cattlewomen</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">RSR Construction</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Estes Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Eclips Hair Studio</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Bry Logging</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Vine Kids</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Kids First Pediatrics-Troy</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Kimble Adamson Construction</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Find Lelia Faulkner/The Faulkner Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Hermeling Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Olivia and Zoey</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Lee Sikes Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Teague Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Jon and Karen Drisko</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Ashley and Josie</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Adam Sanders Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Stephens Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Howard Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Jerry Boyett Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Estes Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Meredith Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">Brooklee & Nolan</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Lane Dodson Family</a>
        </h3>
    </div>
</div>


<div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
    <div class="p-5">
        <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <a href="#">The Chad Woodham Family</a>
        </h3>
    </div>
</div>

          
          
      </div>  
  </div>
</section>



<section>
  <div class="py-8 px-4 mx-auto max-w-screen-xl dark lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white font-mountains-of-christmas">We Operate On Your Generosity</h2>
          <p class="mb-5 font-light text-white sm:text-xl font-farro">You can drop donations in the tube located at the end of the drive-through portion beside the gate, or you can donate using one of the methods below.</p>
      </div>
      <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          
          <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">Cash App</h3>
          
              <div class="flex justify-center items-baseline my-8">
                  <span class="mr-2 text-2xl font-extrabold">$ChristmasinAnsley</span>
                 
              </div>
          </div>
          
          <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">Venmo</h3>
              
              <div class="flex justify-center items-baseline my-8">
                  <span class="mr-2 text-2xl font-extrabold">@ChristmasinAnsley</span>
           
              </div>
          </div>
      
          <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">Paypal</h3>
             
              <div class="flex justify-center items-baseline my-8">
                  <span class="mr-2 text-2xl font-extrabold">ChristmasinAnsley@gmail.com</span>
             
              </div>
          </div>
        </div>
  </div>
</section>
<div class="text-center">
          <a href="#" class="font-mountains-of-christmas text-xl pt-2 py-2.5 px-5 mr-2 mb-2 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Back To Top</a> 
      </div>
      <li></li>
      
</body>
  );
}

export default App;
